// Mailr-sdk

var Mailr = function(options) {
  options = options || {};
  this._id = options.id || 'mailr';
  this._$form = $('#' + this._id);
  if (!this._$form.length) throw new Error('Mailr failed to initialise, no form found');
}

Mailr.prototype.init = function() {
  var self = this;
  this._$form.submit(function(e) {
    e.preventDefault();
    self._send();
  });
  $('input, textarea', this._$form).focus(function() {
    self._reset();
  });
};

Mailr.prototype._send = function() {
  this._changeText('Sending');
  var self = this;
  $.post(this._$form.prop('action'), this._$form.serialize(), function(data) {
      if (data.success) return self._handleSuccess();
  });
};

Mailr.prototype._reset = function() {
  this._changeText('Send');
};

Mailr.prototype._handleSuccess = function() {
  this._changeText('Sent!');
  this._$form[0].reset();
};

Mailr.prototype._changeText = function(text) {
  $('button', this._$form).html(text);
};

module.exports = Mailr;
