var Mailr = require('./mailr');
var mailr = new Mailr({ id: 'contact-form' });
mailr.init();

$(function() {

  /* ======= Scrollspy ======= */
  $('body').scrollspy({
    target: '#topbar',
    offset: 100
  });

  /* ======= ScrollTo ======= */
  $('.scrollto').on('click', function(e) {

    //store hash
    var target = this.hash;

    e.preventDefault();

    $('body').scrollTo(target, 800, {
      offset: -50,
      'axis': 'y'
    });
    //Collapse mobile menu after clicking
    if ($('.navbar-collapse').hasClass('in')) {
      $('.navbar-collapse').removeClass('in').addClass('collapse');
    }

  });

  /* ======= Fixed Header animation ======= */
  $(window).on('scroll load', function() {

    if ($(window).scrollTop() > 50) {
      $('#topbar').addClass('topbar-scrolled');
    } else {
      $('#topbar').removeClass('topbar-scrolled');
    }
  });

  /* ======= jQuery Responsive equal heights plugin ======= */
  /* Ref: https://github.com/liabru/jquery-match-height */

  $('#services .item-desc').matchHeight();
  $('#reviews .quote').matchHeight();


  //Only animate elements when using non-mobile devices
  if (isMobile.any === false) {

    /* Animate elements in #promo */
    $('#promo .heading').css('opacity', 0).one('inview', function(isInView) {
      if (isInView) {
        $(this).addClass('animated fadeInDown delayp1');
      }
    });
    $('#promo .intro').css('opacity', 0).one('inview', function(isInView) {
      if (isInView) {
        $(this).addClass('animated fadeInUp delayp1');
      }
    });

    /* Animate elements in #services */
    $('#services .custom-icon, #faqs .custom-icon').css('opacity', 0).one('inview', function(event, isInView) {
      if (isInView) {
        $(this).addClass('animated fadeInUp delayp1');
      }
    });

    /* Animate elements in #why */
    $('#why .badge-item').css('opacity', 0).one('inview', function(event, isInView) {
      if (isInView) {
        $(this).addClass('animated bounceIn delayp1');
      }
    });


  }

});
